import { Stage, Graphics } from '@inlet/react-pixi';
import React, { useEffect, useState } from 'react';
import * as PIXI from "pixi.js";
import styles from "./canvas.module.scss";
import Dragable from "../Dragable/Dragable";
import PixiStore from '../../store/pixiStore';
import { observer } from 'mobx-react-lite';

const Canvas = observer(() => {
  const [coordinate,setCoordinate]= useState({x:400,y:300});
  const pixiStore = PixiStore.getInstance();

  const renderBoxes = () =>{
    return pixiStore.boxes.map(box=>(
      <Dragable x={box.x} y={box.y} id={box.id} duration={box.duration} key={box.id}/>
    ))
  }
  return (
      <Stage id="canvas" className={styles.canvas} width={window.innerWidth} options={{ backgroundColor: 0xeef1f5 }}>
        {/* <Dragable x={coordinate.x} y={coordinate.y} setCoordinate={setCoordinate}/> */}
        {renderBoxes()}
      </Stage>
  );
});

export default Canvas;