import './App.css';
import * as Tone from "tone";
import React, {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';
import ToneStore, { Song } from "./store/toneStore";
import { runInAction } from "mobx";
import {observer} from "mobx-react-lite";
import Canvas from './components/Canvas/Canvas';


const App =  observer(() => {

  if (!Tone.UserMedia.supported){
    console.log("Tone.UserMedia.supported not supported");
  }
  const [delays,setDelays]=useState([0]);
  const [status,setStatus]=useState("stopped")
  ToneStore.getInstance().loadSongs();
  useEffect(() => {

    setInterval(() => {
      const toneStore = ToneStore.getInstance();
      if (toneStore.meter){
        const value = toneStore.meter.getValue() as number;
//        if (value > -40){
        runInAction(() => {
          const data = toneStore.micData;
          data.datasets[0].data.push(value);
          toneStore.micData = data;
          setStatus(Tone.Transport.state)
        });

          // console.log("Tick");
//        }

      }
    }, 1000);
  }, []);

  const togglePlay = (songs:Song, idx:number) => {
    const toneStore = ToneStore.getInstance();
    toneStore.toggleSongPlayback(songs, idx);
  }

  const onRecord = async () => {
    const toneStore = ToneStore.getInstance();
    await toneStore.toggleRecord();
  }

  const onPlayback = () => {
    const toneStore = ToneStore.getInstance();
    toneStore.togglePlayback();
  }

  const toneStore = ToneStore.getInstance();

  const updateDelays = (name:number,time:number)=>{
    time = time>=0?time:0;
    let arr = delays;
    arr[name]=time;
    setDelays([...arr]);
    toneStore.setStart(name,time)
  }
  const renderSong = (song:Song,idx:number) => {
    
    return (song.player && <tr key={song.title} >
        <th>{song.title}</th>
       {song.name!=0? <th><input type="number" defaultValue={song.position} value={delays[song.name]} onChange={(e)=>{updateDelays(song.name,e.target.valueAsNumber)}}></input></th>
       :<th>{song.position}</th>}
        {song.name!=0&&( <th><button onClick={()=>{toneStore.clear(song.name)}}>dispose</button></th>)}
      </tr>)
  }
  
 return (
    <div className="App">
      <h1>Social Audio</h1>
      <button onClick={onRecord}>{toneStore.isRecording ? "stop recording" : "record"}</button>&nbsp;
       {/* <button disabled = {!toneStore.recording} onClick={onPlayback}>{toneStore.playbackStarted ?  "stop" : "play"}</button> */}
      <div>Concurrent Tracks: {toneStore.songs.length}</div>
      {toneStore.loaded && <table className="playlist"><thead><tr><th>Track</th><th>Start on</th></tr></thead>
        <tbody >
          {toneStore.songs.map((song, idx) => {return renderSong(song, idx)})}
        </tbody>
      </table>}
      {!toneStore.hasLoaded && "Loading..."}
      {/* <div>length:{ toneStore.micData.datasets[0].data.length }</div> */}
    
      {toneStore.loaded && <button onClick={() => toneStore.togglePlayTransport()}>{status==="started"? "Stop Playback" : "Start Playback"}</button>}
      {toneStore.loaded && <button onClick={() => toneStore.download()}>download JSON</button>}
      {/* <div>{toneStore.download()}</div> */}

      <Canvas/>
    </div>

  );
});

export default App;
