import {makeAutoObservable, observable,} from "mobx";
import * as PIXI from "pixi.js";
import ToneStore from "./toneStore";

export interface Boxes {
  id:number,
  x:number,
  y:number,
  percentage:number,
  duration:number,
}

export default class PixiStore{
  renderer = PIXI.autoDetectRenderer();

  static instance: PixiStore | null = null;

  static getInstance(): PixiStore {
    if (PixiStore.instance === null){
      PixiStore.instance = new PixiStore();
    }
    return PixiStore.instance;
  }

  constructor(){
    makeAutoObservable(this);
    this.init();
  }

  private init = ():void => {

  }

  @observable boxes:Boxes[]= []

  addBox(id:number,percentage:number,duration:number){
    this.boxes.push({id:id,x:this.percentageToCoordinate(percentage),y:parseInt((id-1).toString().slice(-1))*45,percentage:percentage,duration:duration})
  }

  removeBox(id:number){
    this.boxes = this.boxes.filter(box=>box.id!=id)
  }


  setCoordinate(id:number,x:number,y:number){
    this.boxes.map(box=>{
      if(box.id==id){
        box.x=x;
        box.y=y;
    }});
    ToneStore.getInstance().setStartWithVisual(id,this.coordinateToPercentage(x));
  }

  percentageToCoordinate = (percentage:number) =>{ 
    return(percentage*this.renderer.width);
  }
  coordinateToPercentage = (posX:number) =>{ 
    return(posX/this.renderer.width);
  }
}