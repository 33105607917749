import { action, computed, makeAutoObservable, observable, runInAction } from "mobx";
import { Meter, Player, Players, Recorder, UserMedia,Context, Clock, Channel ,Transport} from "tone";
import * as Tone from "tone";
import PixiStore from "./pixiStore";


export interface Song{
  name: number,
  player: Player | null,
  playing: boolean,
  title: string,
  position: number,
  blobUrl: string | null,
  blob:Blob|null
}

export default class ToneStore{

  static instance: ToneStore | null = null;
  static getInstance(): ToneStore {
    if (ToneStore.instance === null){
      ToneStore.instance = new ToneStore();
    }
    return ToneStore.instance;
  }

  constructor(){
    makeAutoObservable(this);
    this.init();
  }

  private init = ():void => {
    if (!this.mic){
      this.mic = new Tone.UserMedia();
      this.recorder = new Tone.Recorder();
      this.meter = new Tone.Meter();
      this.mic.connect(this.recorder);
      this.recorder.debug = true;
      this.mic.connect(this.meter);
    }
  }

  // node.js basic types
    mic: UserMedia | null = null;
    recorder: Recorder | null = null;
    player: Player | null = null;
    meter: Meter | null = null;
    


  //TODO:  app variables, need to be moved
  @observable songs = [
    {name: 0, player: null, playing: false, title: "Base Audio", position: 0} as Song,
 ];
  private isLoading: boolean = false;

  loaded: boolean = false;
  playbackStarted: boolean = false;
  isRecording: boolean = false;
  recording: Blob | null = null;
  concurrentSongs: number = 0;
  recordStartOn: number = 0;
  count:number = 0;

  micData = {
    labels: ['1', '2', '3', '4', '5', '6'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19],
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  };

  async loadSongs(){

    if (!this.loaded && !this.isLoading){
      this.isLoading = true;
      if (this.mic === null){
        this.init();
      }

      const players = new Tone.Players(
        {
          "0":"/music/test.mp3",
        }
      ).toDestination();
      await Tone.loaded();
      this.storeSongs(players);
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  get hasLoaded(){
    return this.loaded;
  }

  storeSongs(players: Players){
    if (!this.loaded){
      this.loaded = true;
      console.log("loaded all songs");

      this.songs = [
        {...this.songs[0], player: players.player("0")},
      ];
      this.makeChannel(this.songs[0],0,0)
    }
  }
  
  storeSong(player:Player, name:number, title:string, blobUrl:string,blob:Blob,position:number=this.recordStartOn){
    if (!this.loaded && player){
      this.loaded = true;
      const song:Song={player: player,title:title, name:name ,playing:false,position:position,blobUrl:blobUrl,blob:blob}
      this.songs.unshift(song);
      this.makeChannel(song,position,0);
      
      //visual
      PixiStore.getInstance().addBox(name,this.timeToPercentage(position),this.timeToPercentage(player.buffer.duration));
    }
    return null;
  }

  async download(){
    const result = await Promise.all(this.songs.map(song=>
      {
        if(song.blob){
          return song.blob?.text().then(res=>{
            return {
              name:song.title,
              blobUrl: song.blobUrl||null,
              position:song.position,
              embedded:res||null
            }
          })
        }else{
          return {
            name:song.title,
            blobUrl: song.blobUrl||null,
            position:song.position,
            embedded:null
          }
        }
    }))
    let dataStr= JSON.stringify(result);
    let dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
    let exportFileDefaultName = 'data.json';
    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
    return dataStr;
  }

  async makeChannel(song:Song | null, startTime:number, pan:number) {
    if(startTime>=0 && song && song.player && this.recorder){
      console.log("channel created with song: ",song?.title);
      const channel = new Tone.Channel({
        pan
      })
      channel.toDestination();      
      if(song.player.loaded){
        song.player.sync().start(startTime);
        song.player.connect(channel);
      }
      // song.player.connect(this.recorder);
    }
  }

  percentageToTime(percentage:number){
    const song = this.songs.find(song=>song.name==0)
    const duration= song?.player?.buffer.duration
    if(duration){
      return (duration*percentage);
    }
    return 0;
  }
  timeToPercentage(time:number){
    const song = this.songs.find(song=>song.name==0)
    const duration= song?.player?.buffer.duration
    if(duration){
      return (time/duration);
    }
    return 0;
  }

  setStartWithVisual(id:number,percentage:number){
    const time = this.percentageToTime(percentage);
    this.setStart(id,time);
    console.log(this.songs.length);
    
  }

  setStart(id:number,time:number){
    this.songs.map(song=>{
      if(song.player&&song.name==id){
        song.player.unsync();
        song.player.sync().start(time);
        song.position=time;
      }});
  }

  togglePlayTransport(){
    if(Tone.Transport.state==="started"){
      Tone.Transport.stop()
    }else if (Tone.Transport.state==="stopped"){
      Tone.start()
      Tone.Transport.start()
    }
  }

  toggleSongPlayback(songs:Song, idx:number) {
    const toneStore = ToneStore.getInstance();
    const s = toneStore.songs;
    
    s[idx].playing = !s[idx].playing;
    if (s[idx].playing){
      s[idx].position = 0;
    }

    toneStore.songs = s;

    const song = toneStore.songs[idx];
    if (song.player){      
      if (song.player.state === "started"){
        song.player.stop();
      }else{
        song.player.start();
        
      }
    }

    const concurrentSongs = toneStore.songs.reduce((acc, song) => {
      return acc + (song.playing ? 1 : 0);
    }, 0);
    toneStore.concurrentSongs = concurrentSongs;
  }

  togglePlayback(){
    if (this.player){
      if (this.playbackStarted){
        this.player.stop();
        this.playbackStarted = true;

      }else{
        this.player.start();
        this.playbackStarted = false;
      }
    }
  }

  clear(id:number){
    Tone.Transport.stop()
    Tone.Transport.clear(id); 
    this.songs.map(song=>{
      if(song.player&&song.name==id){
        song.player.unsync();
      }
    });
    this.songs = this.songs.filter(song=>song.name!=id);
    PixiStore.getInstance().removeBox(id);

  }
 async toggleRecord() {
    if (Tone.context.state !== 'running') { // https://github.com/Tonejs/Tone.js/issues/341
      Tone.context.resume();
    }

    if (this.mic && this.recorder){
      if (!this.isRecording){
        await this.mic.open();
        await this.recorder.start();
        console.log("Mike is now open and recording");
        runInAction(() => {
          this.isRecording = true;
          this.recordStartOn=Tone.Transport.seconds;
        });
      } else{
        const data = await this.recorder.stop();
        const blobUrl = URL.createObjectURL(data);
        console.log(data);
        // const anchor = document.createElement("a");
        // anchor.download = "recording.webm";
        // anchor.href = blobUrl;
        // anchor.click();
        
        this.loaded = false;
        this.count++;
        const p = await new Tone.Player(blobUrl, () => {
          console.log("stopped recording");
          this.storeSong(p,this.count,`record-${this.count}`,blobUrl,data)
          runInAction(() => {
            this.recording = data;
          });
        }).toDestination();
        runInAction(() => {
          // @ts-ignore
          this.mic.close();
          this.player = p;
          this.isRecording = false;
        });
        console.log("RESULT ", {
          player: p,
          data: data,
          blobUrl: blobUrl,
        });
        
      }
    }
  }

}
