import {  Graphics } from '@inlet/react-pixi';
import React from 'react';
import * as PIXI from "pixi.js";
import PixiStore from '../../store/pixiStore';
import { render } from '@testing-library/react';

interface PixiDraggable extends PIXI.DisplayObject {
  data: PIXI.InteractionData | null;
  dragging: boolean;
}

const Dragable =({ x = 400, y = 300, ...props }) =>{
  
  const pixiStore = PixiStore.getInstance();

  const renderer = PIXI.autoDetectRenderer();

  const onDragStart = (event: PIXI.InteractionEvent) => {
    const sprite = event.currentTarget as PixiDraggable;

    sprite.alpha = 0.5;
    sprite.data = event.data;
    
    sprite.dragging = true;
  };

  const onDragEnd = (event: PIXI.InteractionEvent) => {
    const sprite = event.currentTarget as PixiDraggable;

    sprite.alpha = 1;
    sprite.dragging = false;
    const arr = {x:sprite.x,y:sprite.y}
    pixiStore.setCoordinate(props.id,arr.x,arr.y);
    sprite.data = null;
  };

  const onDragMove = (event: PIXI.InteractionEvent) => {
    const sprite = event.currentTarget as PixiDraggable;
    if (sprite.dragging) {
      const newPosition = sprite.data!.getLocalPosition(sprite.parent);
      if(newPosition.x>renderer.width){
        sprite.x =renderer.width
      }else if(newPosition.x<0){
        sprite.x =0
      }else{
        sprite.x = newPosition.x;
      }
      // sprite.y = newPosition.y;
    }
  };

  function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '0x';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }


  const draw = React.useCallback(g => {
    g.clear()
    // g.lineStyle(2, 0x464646, 0.5)
    g.beginFill(getRandomColor(), 1)
    g.drawRect(0, 0, props.duration*renderer.width, 40)
    g.endFill()
  }, [])

  return (
    <Graphics draw={draw} 
      scale={1}
      x={x}
      y={y}
      anchor={0.5}
      interactive
      buttonMode
      pointerdown={onDragStart}
      pointerup={onDragEnd}
      pointerupoutside={onDragEnd}
      pointermove={onDragMove} />
  );
}

export default Dragable;